<template>
  <div class="">
    <BaseElFormItem label="條件類型" prop="conditionType">
      <BaseElSelect
        v-model="syncFormData.conditionType"
        :disabled="!syncFormData.enable"
        @change="onConditionChange"
      >
        <BaseElSelectOption
          v-for="option in conditionOptionsConfig"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </BaseElSelect>
    </BaseElFormItem>

    <BaseElFormItem
      v-if="syncFormData.conditionType === 'periodAmount'"
      prop="name"
      label-position="left"
    >
      <template slot="label">
        <div class="inline-flex items-center" style="gap: 12px">
          指定期間
          <BaseElFormItem prop="months">
            <BaseElInput
              v-model="syncFormData.months"
              style="width: 50px"
              :disabled="!syncFormData.enable"
            />
          </BaseElFormItem>
          月，消費滿
          <BaseElFormItem prop="amount">
            <BaseElInput
              v-model="syncFormData.amount"
              style="width: 100px"
              :disabled="!syncFormData.enable"
            />
          </BaseElFormItem>
          元，可{{ wording }}至此會員級別
        </div>
      </template>
    </BaseElFormItem>

    <BaseElFormItem
      v-if="syncFormData.conditionType === 'periodCount'"
      prop="name"
      label-position="left"
    >
      <template slot="label">
        <div class="inline-flex items-center inline" style="gap: 12px">
          指定期間
          <BaseElFormItem prop="months">
            <BaseElInput
              v-model="syncFormData.months"
              style="width: 50px"
              :disabled="!syncFormData.enable"
            />
          </BaseElFormItem>
          月，消費滿
          <BaseElFormItem prop="amount">
            <BaseElInput
              v-model="syncFormData.amount"
              style="width: 100px"
              :disabled="!syncFormData.enable"
            />
          </BaseElFormItem>
          次，可{{ wording }}至此會員級別
        </div>
      </template>
    </BaseElFormItem>

    <BaseElFormItem
      v-if="syncFormData.conditionType === 'onceAmount'"
      prop="name"
    >
      <template slot="label">
        <div class="inline-flex items-center" style="gap: 12px">
          單次消費滿
          <BaseElFormItem prop="amount">
            <BaseElInput v-model="syncFormData.amount" style="width: 100px" />
          </BaseElFormItem>
          元，可{{ wording }}至此會員級別
        </div>
      </template>
    </BaseElFormItem>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useVModel } from '@vueuse/core'
import { conditionOptionsConfig } from '@/config/memberLevel'

export default defineComponent({
  name: 'LevelConditionFileds',
  props: {
    formData: Object,
    wording: { type: String, default: '維持' },
  },
  emits: ['update:formData', 'clear'],
  setup (props, { emit }) {
    const syncFormData = useVModel(props, 'formData', emit)

    const onConditionChange = () => {
      syncFormData.value.months = null
      syncFormData.value.amount = null
      emit('clear')
    }

    return { syncFormData, conditionOptionsConfig, onConditionChange }
  },
})
</script>

<style lang="postcss" scoped></style>
