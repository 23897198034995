<template>
  <div class="basic-info-block side-highlight-container">
    <p class="card-title">基本資訊</p>
    <BaseElForm
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
      label-width="top"
    >
      <BaseElFormItem label="等級階層" prop="level">
        <BaseElSelect v-if="!memberLevelId" v-model="formData.level">
          <BaseElSelectOption
            v-for="level in levelConfig"
            :key="level.value"
            :value="level.value"
            :label="level.label"
          />
        </BaseElSelect>
        <p v-if="memberLevelId">{{ formData.level }}</p>
      </BaseElFormItem>
      <BaseElFormItem prop="name">
        <template slot="label">
          <div class="inline-flex" style="gap: 8px">
            <p>等級名稱</p>
            <p class="label-hint">( 建議 5 個字元，限用中、英、數字 )</p>
          </div>
        </template>
        <BaseElInput v-model="formData.name" clearable placeholder="請輸入名稱" />
      </BaseElFormItem>
      <BaseElFormItem label="設定有效期限">
        <BaseElRadioGroup v-model="formData.validTimeUnit" class="flex flex-col">
          <BaseElRadio label="forever">永久</BaseElRadio>
          <BaseElRadio v-if="formData.level !== 1" label="month">
            <div class="inline-flex items-center" style="gap: 12px">
              有效期限
              <BaseElFormItem prop="validTime">
                <BaseElInput v-model="formData.validTime" style="width: 50px" />
              </BaseElFormItem>
              月
            </div>
          </BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  nextTick,
  watch,
  computed,
  ref,
  reactive,
} from 'vue'
import {
  isDigitRules,
  noEmptyRules,
  minRules,
  lengthRules,
  noSymbolRules,
} from '@/validation'
import { levelConfig } from '@/config/memberLevel'
import FormsContext from '../formsContext'
import { useRoute } from 'vue-router/composables'
import { get, toNumber } from 'lodash'
import formUtils from '@/utils/form'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'MemberLevelBasicInfoBlock',
  props: {
    configData: { type: Object, default: () => ({}) },
    watchData: { type: Object, default: () => ({}) },
  },
  emits: ['update:watchData'],
  setup (props, { emit }) {
    const route = useRoute()
    const formRef = ref(null)
    const memberLevelId = computed(() => route.params.id)
    const syncWatchData = useVModel(props, 'watchData', emit)

    const formData = reactive({
      level: 1,
      name: null,
      validTimeUnit: 'forever',
      validTime: null,
    })

    const formRules = computed(() => {
      const rules = {
        level: [noEmptyRules()],
        name: [noEmptyRules(), lengthRules(1, 5), noSymbolRules()],
        validTimeUnit: [noEmptyRules(), isDigitRules(), minRules(1)],
      }

      if (formData.validTimeUnit === 'month') {
        rules.validTime = [noEmptyRules(), isDigitRules(), minRules(1)]
      }

      return rules
    })

    const compactData = computed(() => {
      const data = formData
      // data handle here
      return {
        level: get(data, 'level'),
        name: get(data, 'name'),
        validTimeUnit: get(data, 'validTimeUnit'),
        validTime: toNumber(get(data, 'validTime')) || null,
      }
    })

    watch(formRules, () => {
      setTimeout(() => {
        formUtils.clearValidate(formRef.value)
      }, 0)
    })

    watch(compactData, () => {
      syncWatchData.value.validTimeUnit = get(
        compactData.value,
        'validTimeUnit',
      )
      FormsContext.setFormData('basicInfo', compactData.value)
    })

    const syncData = () => {
      const data = props.configData
      formData.name = get(data, 'name')
      formData.level = get(data, 'level')

      const validTimeUnit = get(data, 'validTimeUnit')
      formData.validTimeUnit = validTimeUnit

      if (validTimeUnit !== 'forever') {
        formData.validTime = get(data, 'validTime')
      }
    }

    onMounted(async () => {
      await nextTick()
      FormsContext.setFormRef('basicInfo ', formRef.value)
      if (memberLevelId.value) syncData()
    })

    return {
      formRef,
      formData,
      formRules,
      levelConfig,
      memberLevelId,
    }
  },
})
</script>

<style scoped lang="postcss">
.label-hint {
  @apply font-medium text-sm text-gray-80;
}
</style>
